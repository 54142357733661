import React, { useEffect, useState } from 'react';
import { request, gql } from 'graphql-request';
import './merchModal.css';

const SHOPIFY_API_URL = 'https://y2qbfr-3m.myshopify.com/api/2023-01/graphql.json';
const SHOPIFY_ACCESS_TOKEN = 'c9832af0fd39557fc08a96b321639b6e';

const FETCH_PRODUCTS_QUERY = gql`
  {
    products(first: 10) {
      edges {
        node {
          id
          title
          images(first: 1) {
            edges {
              node {
                src
              }
            }
          }
          variants(first: 1) {
            edges {
              node {
                id
                price {
                  amount
                  currencyCode
                }
                selectedOptions {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MerchModal = ({ show, onClose }) => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await request(SHOPIFY_API_URL, FETCH_PRODUCTS_QUERY, null, {
          'X-Shopify-Storefront-Access-Token': SHOPIFY_ACCESS_TOKEN,
        });
        const products = data.products.edges.map(edge => ({
          id: edge.node.id,
          image: edge.node.images.edges[0]?.node.src,
          description: edge.node.title,
          variants: edge.node.variants.edges.map(variantEdge => ({
            id: variantEdge.node.id,
            price: `${variantEdge.node.price.currencyCode} ${variantEdge.node.price.amount}`,
            options: variantEdge.node.selectedOptions,
          })),
        }));
        setItems(products);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    if (show) {
      fetchProducts();
    }
  }, [show]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="vintage-terminal-overlay">
      <div className="vintage-terminal-content">
        <div className="modal-title-bar">
          <span className="modal-title">merch</span>
          <div>
            <button onClick={onClose} className="exit-button">-</button>
            <button onClick={onClose} className="exit-button">X</button>
          </div>
        </div>
        <div className="lower-container" style={{ height: '0%' }}>
        </div>
        <h1>products coming soon</h1>
        {/* <div id="collection-component-1728847799346" style={{margin: '10px', overflow: 'scroll' }}></div> */}
      </div>
    </div>
  );
};

export default MerchModal;